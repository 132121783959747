
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import { when } from '@/service/Switch';

import { VueLoading } from 'vue-loading-template';

import EatingStatusItem from '@/components/molecules/EatingStatusItem.vue';
import SleepList from '@/components/organisms/RecordList/SleepList.vue';
import SleepModal from '@/components/modal/SleepModal.vue';
import TemperatureList from '@/components/organisms/RecordList/TemperatureList.vue';
import TemperatureModal from '@/components/modal/TemperatureModal.vue';
import DinnerList from '@/components/organisms/RecordList/DinnerList.vue';
import DinnerModal from '@/components/modal/DinnerModal.vue';
import BreakfastList from '@/components/organisms/RecordList/BreakfastList.vue';
import BreakfastModal from '@/components/modal/BreakfastModal.vue';
import MilkList from '@/components/organisms/RecordList/MilkList.vue';
import MilkModal from '@/components/modal/MilkModal.vue';
import DefecationList from '@/components/organisms/RecordList/DefecationList.vue';
import DefecationModal from '@/components/modal/DefecationModal.vue';
import StateOfChildForm from '@/components/organisms/RecordList/StateOfChildForm.vue';

import { meModule } from '@/store/entityModules/me';
import { dailyReportModule } from '@/store/entityModules/dailyReport';
import { attendanceModule } from '@/store/entityModules/attendance';
import { familyModule } from '@/store/entityModules/family';

// model
import { HomeAttendanceSchema } from '@API/src/component/home/attendance/types';
import {
  DefecationStatus,
  DefecationStatusType,
} from '../../node_modules/chaild-entity/src/component/home/defecation/types';
import { Defecation } from '../model/Others/Defecation';

interface DataType {
  childId: number;
  childName: string;
  selectedDate: string;
  isSleepDialog: boolean;
  isTemperatureDialog: boolean;
  isDinnerDialog: boolean;
  isBreakfastDialog: boolean;
  isMilkDialog: boolean;
  isDefecationDialog: boolean;
  fetchLoading: boolean;
}

export default Vue.extend({
  components: {
    VueLoading,
    EatingStatusItem,
    SleepList,
    SleepModal,
    TemperatureList,
    TemperatureModal,
    DinnerList,
    DinnerModal,
    BreakfastList,
    BreakfastModal,
    MilkList,
    MilkModal,
    DefecationList,
    DefecationModal,
    StateOfChildForm,
  },

  data: (): DataType => ({
    childId: NaN,
    childName: '',
    selectedDate: dayjs().format('YYYY-MM-DD'),
    isSleepDialog: false,
    isTemperatureDialog: false,
    isDinnerDialog: false,
    isBreakfastDialog: false,
    isMilkDialog: false,
    isDefecationDialog: false,
    fetchLoading: false,
  }),

  computed: {
    me() {
      return meModule.me;
    },
    home() {
      return dailyReportModule.home;
    },
    nursery() {
      return dailyReportModule.nursery;
    },
    meals() {
      return dailyReportModule.meals;
    },
    attendance(): HomeAttendanceSchema | null {
      return attendanceModule.attendance;
    },
    mealComment(): string {
      if (this.nursery.meal) {
        return this.nursery.meal.comment;
      }
      return '';
    },
    year(): string {
      return dayjs(this.selectedDate).format('YYYY');
    },
    today(): string {
      return dayjs(this.selectedDate).format('M月D日');
    },
    dayOfTheWeek(): string {
      return dayjs(this.selectedDate).format('ddd');
    },
    isChatEnabled() {
      if (familyModule.family) {
        const children = familyModule.family.children;
        const child = children.find((c) => {
          if (c.childId === this.childId) {
            return c;
          }
        });
        if (!!child && child.nurserySettings.chat.isEnabled) {
          return child.nurserySettings.chat.isEnabled;
        }
      }
      return false;
    },
  },

  methods: {
    routerBack() {
      this.$router.replace('/record', () => {});
    },
    toNextDate() {
      this.selectedDate = dayjs(this.selectedDate)
        .add(1, 'day')
        .format('YYYY-MM-DD');
      this.fetchReport();
      // this.fetchAttendance();
    },
    toPrevDate() {
      this.selectedDate = dayjs(this.selectedDate)
        .add(-1, 'day')
        .format('YYYY-MM-DD');
      this.fetchReport();
      // this.fetchAttendance();
    },
    async fetchReport() {
      this.fetchLoading = true;
      const report = dailyReportModule.fetchReport({
        childId: this.childId,
        date: this.selectedDate,
      });
      const attendance = attendanceModule.fetchAttendance({
        childId: this.childId,
        date: this.selectedDate,
      });
      await Promise.all([report, attendance]);
      this.fetchLoading = false;
    },
    minuteFormat(iso: string | null) {
      if (iso !== null) {
        return dayjs(iso).format('HH:mm');
      }
      return null;
    },
    getDefecationState(state: DefecationStatusType): string {
      return when(state)
        .on(
          (v) => v === DefecationStatus.hard,
          () => '硬い',
        )
        .on(
          (v) => v === DefecationStatus.normal,
          () => '普通',
        )
        .on(
          (v) => v === DefecationStatus.soft,
          () => '軟らかい',
        )
        .otherwise(() => '');
    },
    toChat() {
      this.$router.push({
        path: '/chat',
        name: 'Chat',
        query: {
          childId: String(this.childId),
          childName: this.childName,
          date: this.selectedDate,
        },
      });
    },
    categoryToText(milkCategory: string): string {
      return when(milkCategory)
        .on(
          (v) => v === 'formula',
          () => 'ミルク',
        )
        .on(
          (v) => v === 'breast',
          () => '母乳',
        )
        .otherwise(() => '');
    },
  },

  async mounted() {
    if (typeof this.$route.query.childName === 'string') {
      this.childName = this.$route.query.childName;
    }
    if (typeof this.$route.query.childId === 'string') {
      this.childId = Number(this.$route.query.childId);
      this.fetchReport();
      // this.fetchAttendance();
    } else {
      this.routerBack();
    }

    if (typeof this.$route.query.chatDate === 'string') {
      this.selectedDate = String(this.$route.query.chatDate);
      this.fetchReport();
      // this.fetchAttendance();
    }
  },
});
